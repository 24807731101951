import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { Card, CardBody } from 'reactstrap';
import CombineErrorMessage from 'components/CombineError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

const Licensing = ({ isNewData, isOldData }) => {
  const [hasError, setHasError] = useState(false);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    const err = checkError();
    setHasError(err);
  }, [errors, touched]);
  const checkError = () => {
    return errors.licenseNumber && touched.licenseNumber;
  };
  return (
    <div className="mb-4">
      <Card>
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Licensing and Administration</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
          </div>
          <Field
            size="lg"
            id="licenseNumber"
            name="licenseNumber"
            type="text"
            label="Your broker or morgtage agent license number"
            component={FormikInputField}
            isError={false}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Licensing;
