import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { getUser } from 'utils/UserDetails';
import AccountType from './components/AccountType';
import Licensing from './components/Licensing';
import Identification from 'pages/Profile/common/Identy';
import ProfilePicture from 'pages/Profile/common/ProfilePic';
import { personalInfoValues, brokerPersonalInfoValidation } from 'pages/Profile/common';
import { ADD_PERSONAL_INFO } from 'pages/Profile/components/Investor/components/PersonalInfo/PersonalInfoGraphQL';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { APPROVE_USER_BY_ADMIN } from 'pages/Profile/common/Mutation';
import {
  AGENT_KEY,
  BROKERAGE_DETAILS_KEY,
  BROKER_ROLE_KEY,
  PB_ROLE_KEY,
  PENDING_KEY,
  PERSONAL_INFO_KEY,
  PRINCIPAL_BROKER,
  REJECTED_KEY
} from 'constant';
import { toast } from 'react-toastify';
import ApprovePersonalInfo from 'pages/Profile/common/ApprovePersonalInfo';
import {
  checkIfFormValueExistInData,
  getUpdateValuesFromObj,
  identyKeys,
  personalInfoKeys,
  secondIdentyKeys,
  updatePBAgentApproveSection
} from 'pages/Profile/Helper';
import ErrorScroll from 'components/ErrorScroll';
import { deleteDocandProfileImg } from 'utils/helper';
import SelectedBroker from './components/SelectedBroker';
import Loader from 'components/Loader';
import { RoleContext } from 'context/Role';
import NewDataForm from './NewDataForm';
import { personalInfoCommonValidation } from 'pages/OnBoarding/components/common';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';

const PersonalDetails = () => {
  const [validation, setValidation] = useState(brokerPersonalInfoValidation);
  const { state: approveParam, pathname } = useLocation();
  const navigate = useNavigate();
  const [addPersonalDetails, { loading: addLoading }] = useMutation(ADD_PERSONAL_INFO);
  const [approveSection, { loading }] = useMutation(APPROVE_USER_BY_ADMIN);
  const { roleKey } = useContext(RoleContext);
  const { id, title } = useParams();
  const isProfilePath = pathname.includes('profile');
  const isCompleteProfile = pathname.includes('complete-profile');
  const { userPersonalDetails, isProfile = false, profileKey } = useOutletContext();
  const user = getUser();

  const { data } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    variables: {
      userId: Number(userPersonalDetails.id),
      sectionName: title === PB_ROLE_KEY ? PERSONAL_INFO_KEY : BROKERAGE_DETAILS_KEY
    }
  });

  const newFormData = data?.getSectionUpdatedFields?.updatedFields || {};

  useEffect(() => {
    if (!id) {
      setValidation({ ...brokerPersonalInfoValidation });
    }
    if (isProfilePath && !isCompleteProfile) {
      setValidation({ ...personalInfoCommonValidation, ...brokerPersonalInfoValidation });
    }
  }, [pathname]);

  const initialValues = userPersonalDetails?.id ? userPersonalDetails : personalInfoValues;
  const contentKey = title === PB_ROLE_KEY ? PERSONAL_INFO_KEY : BROKERAGE_DETAILS_KEY;

  const getNavigateRoutes = () => {
    if (isCompleteProfile && roleKey === PB_ROLE_KEY) {
      return navigate('/complete-profile/brokerage-details');
    } else if (isCompleteProfile && roleKey === BROKER_ROLE_KEY) {
      return navigate('/complete-profile/brokerage');
    } else if (isProfilePath && roleKey === BROKER_ROLE_KEY) {
      return navigate('/profile/brokerage');
    } else if (isProfilePath && roleKey === PB_ROLE_KEY) {
      return navigate('/profile/brokerage-details');
    }
  };

  const onSubmit = async (data) => {
    if (pathname.includes('approval')) return onApprovePersonalDetails(data);
    let formData = { ...data };

    if (userPersonalDetails.isPersonalInfo) {
      formData = deleteDocandProfileImg(data, initialValues);
    }

    const castValues = Yup.object(validation).cast(formData);
    const allValues = getUpdateValuesFromObj(userPersonalDetails, castValues);
    if (isProfilePath && !isCompleteProfile) {
      delete allValues.approveSections;
      allValues.isUpdating = true;
      allValues.formSection = roleKey === PB_ROLE_KEY ? PERSONAL_INFO_KEY : BROKERAGE_DETAILS_KEY;
    }

    try {
      const { data: result } = await addPersonalDetails({
        variables: { updatePersonalDetailsId: user.id, ...allValues }
      });
      toast.success(result?.updatePersonalDetails.message);
      getNavigateRoutes();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onApprovePersonalDetails = async (data) => {
    try {
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: updatePBAgentApproveSection(
            userPersonalDetails.approveSections,
            contentKey,
            data.approveValue === 'true' ? true : false
          ),
          status: data.approveValue === 'true' ? 'approved' : 'rejected',
          formSection: contentKey
        }
      });
      toast.success(result.approveUserBySuperAdmin.message);
      return title === PB_ROLE_KEY
        ? navigate(`/approval/${PB_ROLE_KEY}/${id}/brokerage-details`)
        : navigate(`/approval/broker-agent/${id}/e-sign`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderSubmitButton = () => {
    return (
      <div className="mt-4 text-end justify-content-end">
        {addLoading ? (
          <Loader />
        ) : (
          <Button type="submit" color="primary" size="lg">
            {isCompleteProfile ? 'Save and continue' : 'Update'}
          </Button>
        )}
      </div>
    );
  };

  const renderSubmitActionButtons = () => {
    if (
      pathname.includes('approval') &&
      (userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY)
    ) {
      return (
        <div className="mt-4 text-end">
          <Button
            disabled={loading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}
            color="danger"
            className="me-2">
            Reject
          </Button>
          <Button
            disabled={loading}
            color="primary"
            {...{
              type: 'submit',
              name: 'approveValue',
              value: true
            }}>
            Approve Section
          </Button>
        </div>
      );
    }
    if (isCompleteProfile || isProfilePath) {
      return <div className="mt-4 text-end">{renderSubmitButton()}</div>;
    }
  };

  const renderPersonalInfo = () => {
    if (pathname.includes('brokerage-info')) {
      return null;
    }
    const combineUserAprovalPath =
      pathname.includes('approval') || pathname.includes('user') || pathname.includes('profile');

    const brokerOrPB = title === BROKER_ROLE_KEY || title === PB_ROLE_KEY;
    const rolePbOrBroker = roleKey === BROKER_ROLE_KEY || roleKey === PB_ROLE_KEY;
    if (combineUserAprovalPath && !isCompleteProfile && brokerOrPB) {
      return <ApprovePersonalInfo isForm />;
    }
    if (!isCompleteProfile && isProfilePath && rolePbOrBroker) {
      return <ApprovePersonalInfo isForm />;
    }
  };

  const renderSelectedBroker = () => {
    const approvalUserPath = pathname.includes('approval') || pathname.includes('user');
    if (approvalUserPath && !isCompleteProfile && profileKey === AGENT_KEY) {
      return <SelectedBroker userId={userPersonalDetails?.id} />;
    }
  };

  const checkPersonalInfoKeys =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, personalInfoKeys);
  const checkLicenseKeys =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, ['licenseNumber']);
  const checkIdentyKeys =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, identyKeys);
  const checkSecondIdentyKeys =
    pathname.includes('approval') && checkIfFormValueExistInData(newFormData, secondIdentyKeys);

  const renderNewDataForm = () => {
    if (
      pathname.includes('personal-details') &&
      title === PB_ROLE_KEY &&
      userPersonalDetails.isUnderReviewed &&
      userPersonalDetails.approveRequiredSections[PERSONAL_INFO_KEY]
    )
      return (
        <Col>
          <NewDataForm
            userPersonalDetails={userPersonalDetails}
            profileKey={profileKey}
            newFormData={newFormData}
            checkPersonalInfoKeys={checkPersonalInfoKeys}
            checkLicenseKeys={checkLicenseKeys}
            checkIdentyKeys={checkIdentyKeys}
            checkSecondIdentyKeys={checkSecondIdentyKeys}
          />
        </Col>
      );

    if (
      pathname.includes('brokerage-info') &&
      title === BROKER_ROLE_KEY &&
      userPersonalDetails.isUnderReviewed &&
      userPersonalDetails.approveRequiredSections[BROKERAGE_DETAILS_KEY]
    )
      return (
        <Col>
          <NewDataForm
            userPersonalDetails={userPersonalDetails}
            profileKey={profileKey}
            newFormData={newFormData}
            checkPersonalInfoKeys={checkPersonalInfoKeys}
            checkLicenseKeys={checkLicenseKeys}
            checkIdentyKeys={checkIdentyKeys}
            checkSecondIdentyKeys={checkSecondIdentyKeys}
          />
        </Col>
      );
  };

  return (
    <div>
      <Row>
        <Col>
          <Formik
            initialValues={{ ...initialValues }}
            validationSchema={
              approveParam?.approve ? Yup.object().shape({}) : Yup.object({ ...validation })
            }
            enableReinitialize={true}
            onSubmit={onSubmit}
            validateOnMount={true}>
            {({ handleSubmit, setFieldValue }) => (
              <form
                onSubmit={(e) => {
                  const submitter = e.nativeEvent?.submitter;
                  if (submitter?.name && submitter?.value && id) {
                    setFieldValue(submitter.name, submitter.value);
                  }
                  handleSubmit(e);
                }}>
                <ErrorScroll />
                {renderPersonalInfo()}

                <Licensing isOldData={checkLicenseKeys} />
                {renderSelectedBroker()}
                <Identification
                  isOldData={checkIdentyKeys}
                  title="Identification"
                  formKey={{
                    forntDoc: 'identificationDocs',
                    backDoc: 'identificationBackDocs',
                    identyType: 'identificationType',
                    identyNum: 'identificationNumber'
                  }}
                  identyTypesKey={{
                    primary: 'identificationType',
                    secondary: 'secondaryIdentificationType'
                  }}></Identification>
                <Identification
                  isOldData={checkSecondIdentyKeys}
                  title="Secondary Identification"
                  secondary
                  formKey={{
                    forntDoc: 'secondaryIdentificationDocs',
                    backDoc: 'secondaryIdentificationBackDocs',
                    identyType: 'secondaryIdentificationType',
                    identyNum: 'secondaryIdentificationNumber'
                  }}
                  identyTypesKey={{
                    primary: 'identificationType',
                    secondary: 'secondaryIdentificationType'
                  }}></Identification>
                <ProfilePicture optional userPersonalDetails={userPersonalDetails} />
                {renderSubmitActionButtons()}
              </form>
            )}
          </Formik>
        </Col>
        {renderNewDataForm()}
      </Row>
    </div>
  );
};

export default PersonalDetails;
